import firebase from "firebase";
import promiseRetry from "promise-retry";
import { get_store_value } from "svelte/internal";
import { get, writable } from "svelte/store";
import { getDocumentRef, updateDoc } from "./firestoreService";
import { unSubscribeFiles } from "./filesService";
import { offline, online } from "./connectionService";
import { config, getEndpointAsync } from "./configService";
import { user } from "./authService";
import urlJoin from "url-join";
let unSubscribe = null;
export const signOut = () => {
    unSubscribeFiles();
    if (unSubscribe) {
        unSubscribe();
        console.log("Listeners has been stopped!");
    }
    firebase
        .auth()
        .signOut()
        .then(() => {
        firebase.analytics().logEvent("sign_out");
        console.log("Successfully signed out!");
        userData.set({});
        if (!window.location.href.includes("auth"))
            window.location.reload();
    })
        .catch(() => {
        console.error("Error while signing out!");
    });
};
export const createStore = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
            logEvent("login");
            const userRef = getDocumentRef("users/" + user.uid);
            if (unSubscribe)
                unSubscribe();
            if (get_store_value(online) === true)
                promiseRetry((retry, number) => {
                    return userRef.get().catch(err => {
                        // console.info(number + ' try failed.')
                        retry(err);
                    });
                }).then(doc => {
                    if (!doc)
                        return;
                    userData.set({
                        ...(doc.data() || {}),
                        integration: {
                            ...(doc.data().integration || {}),
                            oauth: undefined,
                        },
                    });
                    if (!doc.data()?.photoURL) {
                        updatePhoto(doc);
                    }
                    unSubscribe = userRef.onSnapshot(doc => {
                        if (doc.data() != null &&
                            typeof doc.data() == "object" &&
                            Object.keys(doc.data() || {}).length > 0) {
                            userData.update(m => {
                                return {
                                    ...doc.data(),
                                    integration: {
                                        ...(doc.data().integration || {}),
                                        oauth: undefined,
                                    },
                                };
                            });
                        }
                    }, err => {
                        console.error(err);
                        userData.set({});
                        if (unSubscribe)
                            unSubscribe();
                    });
                });
        }
        else {
            console.info("Refreshing...");
        }
    });
    offline.subscribe(state => {
        if (state === true && unSubscribe)
            unSubscribe();
    });
    userData.subscribe(val => {
        localStorage.setItem("user_data", JSON.stringify(val));
    });
};
const updatePhoto = doc => {
    if (firebase.auth().currentUser?.photoURL)
        fetch(firebase.auth().currentUser?.photoURL)
            .then(res => {
            if (res.status != 200)
                return;
            updateDoc("users/" + doc.data().uid, {
                photoURL: firebase.auth().currentUser?.photoURL,
            });
        })
            .catch(() => console.error("Could not find user image"));
    else
        updateDoc("users/" + doc.data().uid, {
            photoURL: `https://ui-avatars.com/api/${doc
                .data()
                .displayName.trim()
                .replace(/ +/g, "+")
                .replace(/[^a-zA-Z0-9+]/g, "")}/512`,
        });
};
const m_checkLocalStore = () => {
    const store = localStorage.getItem("user_data");
    if (typeof store == "undefined")
        return {};
    if (store == null || store == "undefined")
        return {};
    try {
        return JSON.parse(store);
    }
    catch (err) {
        return {};
    }
};
export const userData = writable(m_checkLocalStore());
export const userSubscriptions = writable([]);
export const userToken = writable(null);
async function logEvent(type) {
    try {
        const endpoint = await getEndpointAsync("user");
        const token = await firebase.auth().currentUser.getIdToken();
        const resp = await fetch(endpoint + `events`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify({ type }),
        }).then(rsp => rsp.json());
        if (resp.status !== "ok") {
            throw resp;
        }
    }
    catch (err) {
        console.warn("It was not possible to log user event");
        console.warn("logEvent", err);
    }
}
async function getStreamingSessionId(token, retries = 10) {
    try {
        const endpoint = get(config).api.streaming;
        if (!endpoint)
            throw new Error("Streaming endpoint is not defined");
        const resp = await fetch(urlJoin(endpoint, "session"), {
            method: "GET",
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
        }).then(rsp => rsp.json());
        if (resp.status !== "ok") {
            throw resp;
        }
        return resp.data;
    }
    catch (err) {
        console.warn("It was not possible to get streaming session id");
        console.warn("getStreamingSessionId", err);
        if (retries > 0) {
            setTimeout(() => getStreamingSessionId(token, retries - 1), 1000);
        }
    }
}
let tokenWatchdogTimeout_handle = null;
export const startUserTokenWatchdog = async () => {
    if (tokenWatchdogTimeout_handle)
        clearTimeout(tokenWatchdogTimeout_handle);
    const { token, expirationTime } = await get(user).getIdTokenResult();
    await getStreamingSessionId(token);
    userToken.set(token);
    console.info("Token refreshed");
    const expires = new Date(expirationTime);
    const now = new Date();
    const diff = expires.getTime() - now.getTime();
    const timeout = Math.max(diff - 1000 * 60 * 1, 1000 * 30);
    tokenWatchdogTimeout_handle = setTimeout(startUserTokenWatchdog, timeout);
};
