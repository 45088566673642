import firebase from "firebase";
import promiseRetry from "promise-retry";
import { get_store_value } from "svelte/internal";
import { writable } from "svelte/store";
import { getEndpointAsync } from "./configService";
import { localizationData } from "./localizationService";
import _ from "lodash";
import { offline, online } from "./connectionService";
import { userData } from "./userService";
import { locale } from "svelte-i18n";

const m_checkLocalStore = type => {
	const store = localStorage.getItem("pricing");

	if (store == null || store[type] == null || store == "undefined" || store[type] == "undefined") {
		if (type == "plans") return [];
		else if (type == "credit") return {};
	}

	try {
		return JSON.parse(store[type]);
	} catch (err) {
		if (type == "plans") return [];
		else if (type == "credit") return {};
	}
};

export const plans_info = writable(m_checkLocalStore("plans"));

export const credit_info = writable(m_checkLocalStore("credit"));

export const fetchPricingData = () => {
	plans_info.subscribe(val => {
		if (val.length == 0) return;

		localStorage.setItem(
			"pricing",
			JSON.stringify({
				plans: val,
				credit: get_store_value(credit_info),
			})
		);
	});

	credit_info.subscribe(val => {
		if (_.isEmpty(val)) return;

		localStorage.setItem(
			"pricing",
			JSON.stringify({
				plans: get_store_value(plans_info),
				credit: val,
			})
		);
	});

	firebase.auth().onAuthStateChanged(user => {
		if (user) {
			console.log("Collecting pricing data...");
			if (get_store_value(online) === true)
				firebase
					.auth()
					.currentUser.getIdToken()
					.then(token => {
						getCredits(token);
						getPlans(token);
					})
					.catch(err => {
						console.error("Error while getting access token!");
						console.error(err);
					});
		}
	});
};

const getCredits = async token => {
	const endpoint = await getEndpointAsync("payment_endpoints");
	const response = await promiseRetry((retry, number) => {
		if (get_store_value(offline) === true) return null;
		else
			return fetch(
				endpoint +
					"v1/credits?currency=" +
					(get_store_value(userData)?.currency ||
						get_store_value(localizationData)?.country?.currency),
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + token,
					},
				}
			)
				.then(res => res.json())
				.catch(retry);
	});

	// console.log('Credits data', response)
	if (response.status === "ok" && !response.empty) credit_info.set(response.data[0]);
};

const getPlans = async token => {
	const endpoint = await getEndpointAsync("payment_endpoints");

	const response = await promiseRetry((retry, number) => {
		if (get_store_value(offline) === true) return null;
		else
			return fetch(
				endpoint +
					"v2/plans?currency=" +
					(get_store_value(userData)?.currency ||
						get_store_value(localizationData)?.country?.currency) +
					"&lang=" +
					(get_store_value(locale) ||
						get_store_value(locale)?.lang?.split("-")[0] ||
						get_store_value(localizationData)?.language?.split("-")[0] ||
						"en"),
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: "Bearer " + token,
					},
				}
			)
				.then(res => res.json())
				.catch(retry);
	});

	if (response.status === "ok") {
		plans_info.set(response.data);
	}
};
