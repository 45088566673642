<script>
	import { onMount } from "svelte";
	import { afterPageLoad } from "@roxi/routify";

	const pathsToDisplayChat = ["/app/account", "/app/files"];

	let hasDisplayChat = false;
	$afterPageLoad(page => {
		hasDisplayChat = pathsToDisplayChat.some(path => page.path.startsWith(path));
	});

	// messenger
	const locale = localStorage.getItem("locale") || "en";

	(function (d, s, id, locale) {
		const src = `https://connect.facebook.net/${
			locale === "pl" ? "pl_PL" : "en_US"
		}/sdk/xfbml.customerchat.js`;
		let js,
			fjs = d.getElementsByTagName(s)[0];

		if (d.getElementById(id)) return;
		js = d.createElement(s);
		js.id = id;
		js.src = src;
		fjs.parentNode.insertBefore(js, fjs);
	})(document, "script", "facebook-jssdk", locale);

	onMount(() => {
		window.fbAsyncInit = function () {
			FB.init({
				xfbml: true,
				version: "v13.0",
			});
		};
	});
</script>

<!-- Messenger Wtyczka czatu Code -->
<div class:hidden={!hasDisplayChat} id="fb-root" />

<!-- Your Wtyczka czatu code -->
<div
	id="fb-customer-chat"
	page_id="110564114045632"
	attribution="biz_inbox"
	class="fb-customerchat" />
