import firebase from "firebase";
import promiseRetry from "promise-retry";
import { get_store_value } from "svelte/internal";
import { get, writable } from "svelte/store";
import { updateDoc } from "./firestoreService";
import { offline, online } from "./connectionService";
import { config, getEndpointAsync } from "./configService";

const uid = null;
let store = null;
let unSubscribe = null;
const unSubscribeShared = null;
const backupFiles = [];
//userData.subscribe(val => val != null ? uid = val.uid : null)

export const unSubscribeFiles = () => {
	if (unSubscribe) {
		unSubscribe();
		unSubscribe = null;
	}
};

try {
	store = localStorage.getItem("files") ? JSON.parse(localStorage.getItem("files")) : [];
	if (!Array.isArray(store)) store = [];
} catch (err) {
	store = [];
}

export const filesStore = writable(store || []);

export const createInstance = uid => {
	if (!uid) return;

	const filesRef = firebase
		.firestore()
		.collection("files")
		.where("user", "==", uid)
		.orderBy("createdAt", "desc");

	filesStore.subscribe(val => {
		if (Array.isArray(val)) {
			localStorage.setItem("files", JSON.stringify(val));
		}
	});

	promiseRetry((retry, number) => {
		if (get_store_value(offline) === true) return null;
		else
			return filesRef.get().catch(err => {
				console.warn("[Files] " + number + " try failed.");
				console.error(err);
				retry(err);
			});
	}).then(latestFiles => {
		if (get_store_value(online) === true)
			filesStore.update(m => {
				const files = latestFiles.docs.map(doc => {
					const data = doc.data();

					return { id: doc.id, shared: false, ...data };
				});

				m = m.filter(f => f.shared === true);
				m = [...m, ...files];
				m = m.filter(f => f.id && f.createdAt && f.fileName && f.duration);

				m.sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds || 0);

				return m;
			});

		if (get_store_value(online) === true)
			unSubscribe = filesRef.onSnapshot(
				docs => {
					filesStore.update(m => {
						docs.docChanges().forEach(doc => {
							if (doc.type == "added") {
								const index = m.findIndex(n => n.id == doc.doc.id);
								if (index > -1) {
									// console.info('Updating existing video...')
									m[index] = {
										id: doc.doc.id,
										...doc.doc.data(),
									};
								} else {
									m = [{ id: doc.doc.id, ...doc.doc.data() }, ...m];
								}
							}

							if (doc.type == "modified") {
								const index = m.findIndex(n => n.id == doc.doc.id);
								if (index < 0) {
									// console.warn('Could not find file to modify in store!')
								} else {
									m[index] = {
										id: doc.doc.id,
										...doc.doc.data(),
									};
								}
							}

							if (doc.type == "removed") {
								const index = m.findIndex(n => n.id == doc.doc.id);
								if (index < 0) {
									// console.warn('Could not find file to remove from store!')
								} else {
									m.splice(index, 1);
									// console.log('Removed file with index: ' + index)

									const removedDocument = doc.doc.data();
									if (removedDocument?.usersCanView?.includes(uid)) {
										getSharedFiles();
									}
								}
							}
						});

						m = m.filter(f => f.id);

						return m.sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds || 0);
					});
				},
				err => {
					console.error(err);
					if (unSubscribe) unSubscribe();
				}
			);

		offline.subscribe(state => {
			if (state === true && unSubscribe) unSubscribe();
		});
	});

	getSharedFiles();

	function getSharedFiles() {
		promiseRetry((retry, number) => {
			if (get_store_value(offline) === true) {
				return null;
			} else {
				return new Promise((resolve, reject) => {
					const tmp_config = get(config)?.teams?.enabled;
					if (typeof tmp_config !== "boolean") reject("Teams enabled not specified");
					if (tmp_config === true) resolve();

					(async function () {
						const endpoint = await getEndpointAsync("shareService/files");
						const token = await firebase.auth().currentUser?.getIdToken();

						const response = await fetch(endpoint, {
							headers: {
								"Content-Type": "application/json",
								Authorization: "Bearer " + token,
							},
						}).then(res => res.json());
						console.log(uid);

						if (response?.status == "ok") {
							filesStore.update(m => {
								m = m.filter(f => !f.shared);
								m = [...m, ...(response.data || [])];
								m = m.filter(
									f =>
										f.id &&
										f.createdAt &&
										f.fileName &&
										("hasVideo" in f || "hasAudio" in f) &&
										f.duration
								);
								m.sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds || 0);
								if (response?.size > 0) {
									console.info("Shared files:" + response.data.length);
									console.info(response.data);
								}
								return m;
							});

							console.info(
								"Shared files in store",
								get_store_value(filesStore).filter(f => f.shared)
							);
						} else {
							console.error("Error while getting shared files");
							throw "Error while getting shared files";
						}
					})()
						.then(() => resolve())
						.catch(err => reject(err));
				}).catch(err => retry(err));
			}
		});
	}
};

export const checkURLS = async file => {
	const m_file = file;

	const storage = firebase.storage();
	const storageRef = storage.ref();

	if (!file.downloadURL || file.downloadURL == null || file.downloadURL == "") {
		try {
			m_file.downloadURL = await storageRef
				.child("uploads/" + file.id + "/original." + file.fileName.split(".").pop())
				.getDownloadURL();
			updateDoc("files/" + m_file.id, {
				downloadURL: m_file.downloadURL,
			});
		} catch (error) {
			console.error(error);
		}
	}

	if (!file.subtitlesURL || file.subtitlesURL == null || file.subtitlesURL == "") {
		try {
			m_file.subtitlesURL = await storageRef
				.child("subtitles/" + file.id + "/original.vtt")
				.getDownloadURL();
			updateDoc("files/" + m_file.id, {
				subtitlesURL: m_file.subtitlesURL,
			});
		} catch (error) {
			console.error(error);
		}
	}

	return m_file;
};

export const downloadRenderedVideo = async (fileId, fileName) => {
	const storage = firebase.storage();
	const storageRef = storage.ref();

	const fileURL = await storageRef
		.child("rendered/" + fileId + "/rendered." + fileName.split(".").pop())
		.getDownloadURL();

	downloadURI(fileURL, fileName);
	return null;
};

async function downloadURI(uri, name) {
	const response = await fetch(uri).then(r => r.blob());
	const blobUrl = URL.createObjectURL(response);

	const link = document.createElement("a");
	link.download = name;
	link.href = blobUrl;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}
