<script>
	import { Router } from "@roxi/routify";
	import { routes } from "../.routify/routes";
	import "./tailwind.css";
	import firebase from "firebase/app";
	import "firebase/firestore";
	import "firebase/auth";
	import "firebase/storage";
	import "firebase/performance";
	import "firebase/analytics";
	import { register, init, getLocaleFromNavigator, locales } from "svelte-i18n";
	import Initialization from "./Initialization.svelte";
	import ServiceWorker from "./ServiceWorker.svelte";
	import LiveChat from "./pages/_components/LiveChat.svelte";
	import TimeAgo from "javascript-time-ago";
	import en from "javascript-time-ago/locale/en.json";
	import pl from "javascript-time-ago/locale/pl.json";

	const DEBUG = true;

	if (!DEBUG) {
		console.log("%cSTOP", "color: red; font-weight: bold; font-size: 50px");
		console.log(
			"%cThis tool is only used by developers. If someone has instructed you to enter commands here in order to obtain benefits or hidden content, they most likely want to steal your data.",
			"font-size: 20px"
		);
		console.log = (...data) => {};
		console.warn = (...data) => {};
		console.info = (...data) => {};
		console.error = (...data) => {
			firebase.analytics().logEvent("exception", { description: data.join(";") });
		};
		console.time = data => {};
		console.timeEnd = data => {};
	}

	// For Firebase JS SDK v7.20.0 and later, measurementId is optional
	const firebaseConfig = {
		apiKey: "AIzaSyCF27sj_1bJ6MjtH9ipZ7ZeHvUzVndk3KQ",
		authDomain: "stenograf-stag.firebaseapp.com",
		projectId: "stenograf-stag",
		storageBucket: "stenograf-stag.appspot.com",
		messagingSenderId: "78019855068",
		appId: "1:78019855068:web:c16c197bb5b1a2d366aa19",
		databaseURL: "https://stenograf-stag-default-rtdb.europe-west1.firebasedatabase.app",
	};
	if (!firebase.apps.length) {
		firebase.initializeApp(firebaseConfig);
	} else {
		firebase.app(); // if already initialized, use that one
	}

	firebase.performance();
	firebase.analytics();

	// firebase.auth().useEmulator('http://localhost:9099/');
	// var db = firebase.firestore();
	// if (location.hostname === 'localhost') {
	//   db.useEmulator('localhost', 8080);
	// }

	register("en", () => import("./locales/en.json"));
	register("pl", () => import("./locales/pl.json"));
	register("uk", () => import("./locales/uk.json"));

	const defaultLang = "en";

	function getInitialLocale() {
		const locale = localStorage.getItem("locale");
		if (locale) {
			if ($locales.includes(locale)) return locale;
			localStorage.setItem("locale", defaultLang);
			return defaultLang;
		} else {
			const lang = getLocaleFromNavigator().split("-")[0];
			localStorage.setItem("locale", lang);
			return lang;
		}
	}

	init({
		fallbackLocale: defaultLang,
		initialLocale: getInitialLocale(),
	});

	window.fbAsyncInit = function () {
		FB.init({
			xfbml: true,
			version: "v13.0",
		});
	};

	try {
		TimeAgo.addLocale(pl);
		TimeAgo.addDefaultLocale(en);
	} catch (err) {
		console.error(err);
	}

	let refreshing;
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.addEventListener("controllerchange", function () {
			if (refreshing) return;
			refreshing = true;
			window.location.reload();
		});
	}

	// (function (d, s, id) {
	// var js,
	//   fjs = d.getElementsByTagName(s)[0];
	// if (d.getElementById(id)) return;
	// js = d.createElement(s);
	// js.id = id;
	// js.src = "";
	// fjs.parentNode.insertBefore(js, fjs);
	// })(document, "script", "facebook-jssdk");
</script>

<svelte:head>
	<!-- Global site tag (gtag.js) - Google Analytics -->
	<!-- <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=G-7X2BXQPCD2"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-7X2BXQPCD2");
  </script>-->
</svelte:head>

<ServiceWorker />

<Initialization />
<Router {routes} />

<LiveChat />

<style global lang="postcss">
	@import "../static/global.css";
	@import "../static/all.css";
</style>
