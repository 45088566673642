import firebase from "firebase/app";
import _ from "lodash";
import { get_store_value } from "svelte/internal";
import { writable } from "svelte/store";
import { getDocument } from "./firestoreService";
import lodash from "lodash";
const TIMEOFFSET = 60 * 5;
const languages = [
    {
        text: "DE",
        value: "de-DE",
        src: "https://flagcdn.com/de.svg",
        alt: "Germany",
    },
    {
        text: "EN",
        value: "en-US",
        src: "https://flagcdn.com/us.svg",
        alt: "United States",
    },
    {
        text: "FR",
        value: "fr-FR",
        src: "https://flagcdn.com/fr.svg",
        alt: "France",
    },
    {
        text: "IT",
        value: "it-IT",
        src: "https://flagcdn.com/it.svg",
        alt: "Italy",
    },
    {
        text: "NL",
        value: "nl-NL",
        src: "https://flagcdn.com/nl.svg",
        alt: "Netherlands",
    },
    {
        text: "UK",
        value: "uk-UA",
        src: "https://flagcdn.com/ua.svg",
        alt: "Ukraine",
    },
    {
        text: "PL",
        value: "pl-PL",
        src: "https://flagcdn.com/pl.svg",
        alt: "Poland",
    },
    {
        text: "RU",
        value: "ru-RU",
        src: "https://flagcdn.com/ru.svg",
        alt: "Russia",
    },
].sort((a, b) => {
    if (a.text < b.text)
        return -1;
    if (a.text > b.text)
        return 1;
    return 0;
});
export const availableLanguages = writable(languages);
const m_checkLocalStore = () => {
    const store = localStorage.getItem("config");
    if (typeof store == "undefined")
        return {};
    if (store == null || store == "undefined")
        return {};
    try {
        return JSON.parse(store);
    }
    catch (err) {
        return {};
    }
};
const tryParse = (str) => {
    try {
        return JSON.parse(str);
    }
    catch (error) {
        return str;
    }
};
export const overrideAdminConfig = () => {
    config.update(config => lodash.merge(config, {
        teams: { enabled: true },
        transcription_view: { enabled: true },
        collections: {
            enabled: true,
            general: { enabled: true },
            folders: { enabled: true },
        },
        files_views: {
            enabled: ["big", "table", "tablev2"],
            tablev2: {
                toolbox: {
                    enabled: true,
                },
                filters: {
                    enabled: true,
                },
                sort: {
                    enabled: false,
                },
                search: {
                    enabled: true,
                },
                multiselect: {
                    enabled: true,
                },
                action: {
                    enabled: true,
                },
            },
        },
    }));
};
export const config = writable(m_checkLocalStore().data || {});
export const fetchConfigData = async () => {
    config.subscribe(val => {
        console.info(val);
        if (_.isEmpty(val))
            return;
        let lastUpdate = 0;
        if ((m_checkLocalStore().lastUpdate || 0) + TIMEOFFSET < Math.round(Date.now() / 1000) &&
            !_.isEqual(val, m_checkLocalStore().data))
            lastUpdate = Math.round(Date.now() / 1000);
        else
            lastUpdate = m_checkLocalStore().lastUpdate;
        localStorage.setItem("config", JSON.stringify({
            data: val,
            lastUpdate,
        }));
    });
    if ((m_checkLocalStore().lastUpdate || 0) + TIMEOFFSET < Math.round(Date.now() / 1000)) {
        const doc = await getDocument("configs/_general");
        console.info("Config firestore", doc);
        config.update(value => ({
            ...value,
            ...doc,
        }));
    }
    try {
        const remoteConfig = firebase.remoteConfig();
        await remoteConfig.fetchAndActivate();
        const raw = remoteConfig.getAll();
        const array = Object.entries(raw).map(([key, value]) => [key, tryParse(value.asString())]);
        console.info("Config remote", Object.fromEntries(array));
        config.update(value => ({
            ...value,
            ...Object.fromEntries(array),
        }));
    }
    catch (error) {
        console.error(error);
    }
};
export const getEndpointAsync = async (name = null, end = true) => {
    while (!get_store_value(config).api)
        await new Promise(resolve => setTimeout(resolve, 50));
    return (get_store_value(config).api.host +
        (name != null && end == true ? "/" : "") +
        (name == null ? "" : name + (end ? "/" : "")));
};
export const getCollabEndpointAsync = async (name = null, end = true) => {
    while (!get_store_value(config).api)
        await new Promise(resolve => setTimeout(resolve, 50));
    return (get_store_value(config).api.collab +
        (name != null && end == true ? "/" : "") +
        (name == null ? "" : name + (end ? "/" : "")));
};
export async function getCollabAddress() {
    while (!get_store_value(config).api)
        await new Promise(resolve => setTimeout(resolve, 50));
    return get_store_value(config).api.collab;
}
export async function isCollabEnabled() {
    const address = await getCollabAddress();
    return !!address;
}
export const refConfig = (type = "main") => {
    return firebase.firestore().doc("configs/" + type);
};
export const getConfig = async (type = "main") => {
    const result = await refConfig(type).get();
    if (result.exists)
        return result.data();
    return false;
};
export const updateConfig = async (data, type = "main") => {
    let result;
    try {
        result = await refConfig(type).update({
            ...data,
        });
    }
    catch (err) {
        return false;
    }
    return result;
};
